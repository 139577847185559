import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { contractABI, ERC20ABI } from "../utils/constants.js";

import { CONTRACT_ADDRESS } from "../utils/address_constants.js";

const myInitalState = {};

export const TransactionContext = React.createContext(myInitalState);

const USDC_ADDRESS = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";

const { ethereum } = window;

const createEthereumContract = () => {
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();
  const transactionsContract = new ethers.Contract(CONTRACT_ADDRESS, contractABI, signer);

  return transactionsContract;
};

const transactionsContract = createEthereumContract();

const createERC20Contract = (address) => {
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();
  const transactionsContract = new ethers.Contract(address, ERC20ABI, signer);
  return transactionsContract;
};

export const TransactionsProvider = ({ children }) => {
  const [formData, setformData] = useState({
    addressTo: "",
    amount: "",
    keyword: "",
    message: "",
  });
  const [currentAccount, setCurrentAccount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [transactionCount, setTransactionCount] = useState(localStorage.getItem("transactionCount"));
  const [totalSupply, setTotalSupply] = useState(-1);
  const [balanceOf, setBalanceOf] = useState("");
  const [BTCPerShare, setBTCPerShare] = useState(0);
  const [ETHPerShare, setETHPerShare] = useState(0);
  const [UNIPrice, setUniPrice] = useState(0);
  const [ETHPrice, setEthPrice] = useState(0);

  const handleChange = (e, name) => {
    setformData((prevState) => ({ ...prevState, [name]: e.target.value }));
  };

  const checkIfWalletIsConnect = async () => {
    try {
      if (!ethereum) return alert("Please install MetaMask.");

      const accounts = await ethereum.request({ method: "eth_accounts" });

      if (accounts.length) {
        setCurrentAccount(accounts[0]);
        console.log("Account Found " + accounts.length);

        getTotalSupply();
      } else {
        console.log("No accounts found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalSupply = async () => {
    try {
      if (ethereum) {
        const totalSupply = await transactionsContract.totalSupply();
        console.log(totalSupply);
        setTotalSupply(totalSupply);
      } else {
        console.log("Ethereum is not present");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBalanceOf = async () => {
    try {
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const address = await provider.getSigner().getAddress();
        const balanceOf = await transactionsContract.balanceOf(address);
        console.log(balanceOf);
        setBalanceOf(ethers.utils.formatEther(balanceOf));
      } else {
        console.log("Ethereum is not present");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPricePerShare = async () => {
    try {
      if (ethereum) {
        const BTCPricePerShare = await transactionsContract.getPerShare(1);
        setBTCPerShare(BTCPricePerShare.toNumber());
        const ETHPricePerShare = await transactionsContract.getPerShare(2);
        setETHPerShare(ETHPricePerShare.toNumber());
      } else {
        console.log("Ethereum is not present");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkIfTransactionsExists = async () => {
    try {
      if (ethereum) {
        const currentTransactionCount = await transactionsContract.getTransactionCount();

        window.localStorage.setItem("transactionCount", currentTransactionCount);
      }
    } catch (error) {
      console.log(error);

      throw new Error("No ethereum object");
    }
  };

  const connectWallet = async () => {
    try {
      if (!ethereum) return alert("Please install MetaMask.");

      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      setCurrentAccount(accounts[0]);
      window.location.reload();
    } catch (error) {
      console.log(error);

      throw new Error("No ethereum object");
    }
  };

  const sendTransaction = async () => {
    try {
      if (ethereum) {
        const { amount } = formData;
        const parsedAmount = ethers.utils.parseEther(amount);

        const provider = new ethers.providers.Web3Provider(ethereum);
        const address = await provider.getSigner().getAddress();
        const allowance = await createERC20Contract(USDC_ADDRESS).allowance(address, CONTRACT_ADDRESS);
        if (allowance < 1000000000) {
          const transactionHashApprove = await createERC20Contract(USDC_ADDRESS).approve(CONTRACT_ADDRESS, 1000000000);
          await transactionHashApprove.wait();
        }
        console.log("approved");

        const transactionHash = await transactionsContract.deposit(
          USDC_ADDRESS,
          /*usdc*/ 1000000000,
          /*shares*/ amount,
          { gasLimit: 10000000 }
        );
        setIsLoading(true);
        console.log(`Loading - ${transactionHash.hash}`);
        await transactionHash.wait();
        console.log(`Success - ${transactionHash.hash}`);
        setIsLoading(false);
      } else {
        console.log("No ethereum object");
      }
    } catch (error) {
      console.log(error);

      throw new Error("Error");
    }
  };

  const withdraw = async () => {
    try {
      if (ethereum) {
        const { amount } = formData;
        const parsedAmount = ethers.utils.parseEther(amount);
        const transactionHash = await transactionsContract.withdraw(parsedAmount);

        setIsLoading(true);
        console.log(`Loading - ${transactionHash.hash}`);
        await transactionHash.wait();
        console.log(`Success - ${transactionHash.hash}`);
        setIsLoading(false);

        window.location.reload();
      } else {
        console.log("No ethereum object");
      }
    } catch (error) {
      console.log(error);

      throw new Error("No ethereum object");
    }
  };

  useEffect(() => {
    checkIfWalletIsConnect();
    getBalanceOf();
    getPricePerShare();
  }, [transactionCount]);

  return (
    <TransactionContext.Provider
      value={{
        transactionCount,
        connectWallet,
        totalSupply,
        currentAccount,
        isLoading,
        sendTransaction,
        handleChange,
        formData,
        withdraw,
        getBalanceOf,
        balanceOf,
        BTCPerShare,
        ETHPerShare,
        UNIPrice,
        ETHPrice,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};
