import React, { useContext } from "react";
import { AiFillPlayCircle } from "react-icons/ai";
import { SiEthereum } from "react-icons/si";
import { BsInfoCircle } from "react-icons/bs";

import { TransactionContext } from "../context/TransactionContext";
import { shortenAddress } from "../utils/shortenAddress";
import { Loader } from ".";
import { UniswapPriceContext, UniswapPriceProvider } from "../context/UniswapPriceContext";

const companyCommonStyles =
  "min-h-[70px] sm:px-0 px-2 sm:min-w-[120px] flex justify-center items-center border-[0.5px] border-gray-400 text-sm font-light text-white";

const Input = ({ placeholder, name, type, value, handleChange }) => (
  <input
    placeholder={placeholder}
    type={type}
    step="0.0001"
    value={value}
    onChange={(e) => handleChange(e, name)}
    className="my-2 w-full rounded-sm p-2 outline-none bg-transparent text-white border-none text-sm white-glassmorphism"
  />
);

const Welcome = () => {
  const {
    currentAccount,
    connectWallet,
    handleChange,
    sendTransaction,
    withdraw,
    formData,
    isLoading,
    balanceOf,
    BTCPerShare,
    ETHPerShare,
  } = useContext(TransactionContext);

  const { ETHUSDCPrice, BTCUSDCPrice } = useContext(UniswapPriceContext);
  const handleSubmit = (e) => {
    const { amount } = formData;

    e.preventDefault();

    if (!amount) return;

    sendTransaction();
  };

  const handleWithdraw = (e) => {
    const { amount } = formData;

    e.preventDefault();

    if (!amount) return;

    withdraw();
  };
  return (
    <div className="flex w-full justify-center items-center">
      <div className="flex mf:flex-row flex-col items-start justify-between md:p-20 py-12 px-4">
        <div className="flex flex-1 justify-start items-start flex-col mf:mr-10">
          <h1 className="text-3xl sm:text-5xl text-white text-gradient py-1">Fully Decentralized Cryto Index (DeCI)</h1>
          <p className="text-left mt-5 text-white font-light md:w-9/12 w-11/12 text-base">
            Diversify your crypto portfolio through DeCI to hold top ERC20 tokens.
          </p>
          {!currentAccount && (
            <button
              type="button"
              onClick={connectWallet}
              className="flex flex-row justify-center items-center my-5 bg-[#2952e3] p-3 rounded-full cursor-pointer hover:bg-[#2546bd]"
            >
              <AiFillPlayCircle className="text-white mr-2" />
              <p className="text-white text-base font-semibold">Connect Wallet</p>
            </button>
          )}
        </div>

        <div className="flex flex-col flex-1 items-center justify-start w-full mf:mt-0 mt-10">
          <div className="p-3 flex justify-end items-start flex-col rounded-xl h-60 sm:w-72 w-full my-5 eth-card .white-glassmorphism ">
            <div className="flex justify-between flex-col w-full h-full">
              <div className="flex justify-between items-start">
                <div className="w-10 h-10 rounded-full border-2 border-white flex justify-center items-center">
                  <SiEthereum fontSize={21} color="#fff" />
                </div>
                <BsInfoCircle fontSize={17} color="#fff" />
              </div>
              <div>
                <p className="text-white font-light text-sm">{shortenAddress(currentAccount)}</p>
                <p className="text-white font-semibold text-lg mt-1">Ethereum</p>
                <p className="text-white font-semibold text-lg mt-1">{balanceOf}</p>

                <p className="text-white font-semibold text-lg mt-1">BTCPerShare: {BTCPerShare}</p>
                <p className="text-white font-semibold text-lg mt-1">ETHPerShare: {ETHPerShare}</p>

                <p className="text-white font-semibold text-lg mt-1">BTCPrice: {BTCUSDCPrice}</p>
                <p className="text-white font-semibold text-lg mt-1">ETHPrice: {ETHUSDCPrice}</p>
              </div>
            </div>
          </div>
          <div className="p-5 sm:w-96 w-full flex flex-col justify-start blue-glassmorphism">
            <div className="text-white">Shares: </div>
            <div>
              <Input placeholder="Number of shares" name="amount" type="number" handleChange={handleChange} />
            </div>
            <div className="h-[1px] w-full bg-gray-400 my-2" />

            {isLoading ? (
              <Loader />
            ) : (
              <button
                type="button"
                onClick={handleSubmit}
                className="text-white w-full mt-2 border-[1px] p-2 border-[#3d4f7c] hover:bg-[#3d4f7c] rounded-full cursor-pointer"
              >
                Deposit
              </button>
            )}

            {isLoading ? (
              <Loader />
            ) : (
              <button
                type="button"
                onClick={handleWithdraw}
                className="text-white w-full mt-2 border-[1px] p-2 border-[#3d4f7c] hover:bg-[#3d4f7c] rounded-full cursor-pointer"
              >
                Withdraw
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
