import React, { useEffect, useState } from "react";
import { WETH_ADDRESS, USDC_ADDRESS, WBTC_ADDRESS } from "../utils/address_constants.js";
import { createUniswapV3FactoryContract, getUniswapPool } from "../utils/contract_helper.js";

const myInitalState = {};

export const UniswapPriceContext = React.createContext(myInitalState);

// ref: https://docs.uniswap.org/contracts/v3/reference/deployments
const UniswapV3FactoryContractAddress = "0x1F98431c8aD98523631AE4a59f267346ea31F984";

export const UniswapPriceProvider = ({ children }) => {
  // Prices must be in a pair
  const [ETHUSDCPrice, setEthUsdcPrice] = useState("");
  const [BTCUSDCPrice, setBtcUsdcPrice] = useState("");
  const contract = createUniswapV3FactoryContract();
  const getUsdcPrice = async (tokenAddress) => {
    const address = await contract.getPool(tokenAddress, USDC_ADDRESS, 3000);
    const pool = await getUniswapPool(address);
    console.log(pool.token0.address);
    console.log(tokenAddress);
    console.log(pool);
    console.log(pool.token0.address == tokenAddress);
    console.log(pool.token0Price.toSignificant(10));
    console.log(pool.token1Price.toSignificant(10));
    if (pool.token0.address == tokenAddress) {
      return pool.token0Price.toSignificant(10);
    } else {
      return pool.token1Price.toSignificant(10);
    }
  };

  const updatePrice = async () => {
    const ethPrice = await getUsdcPrice(WETH_ADDRESS);
    const btcPrice = await getUsdcPrice(WBTC_ADDRESS);
    setEthUsdcPrice(ethPrice);
    setBtcUsdcPrice(btcPrice);
  };

  useEffect(() => {
    updatePrice();
  });

  return (
    <UniswapPriceContext.Provider
      value={{
        ETHUSDCPrice,
        BTCUSDCPrice,
      }}
    >
      {children}
    </UniswapPriceContext.Provider>
  );
};
