import { Contract, ethers, getDefaultProvider, Signer } from "ethers";
import UniswapV3Factory_json from "@uniswap/v3-core/artifacts/contracts/UniswapV3Factory.sol/UniswapV3Factory.json";
import IUniswapV3Pool_json from "@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json";
import { ERC20ABI } from "../utils/constants.js";

import { Pool } from "@uniswap/v3-sdk";
import { Token } from "@uniswap/sdk-core";
let fs;
if (typeof process !== "undefined") {
  fs = require("fs");
}
// ref: https://docs.uniswap.org/contracts/v3/reference/deployments
const UniswapV3FactoryContractAddress = "0x1F98431c8aD98523631AE4a59f267346ea31F984";
const UNISWAPV3Factory_ABI = UniswapV3Factory_json.abi;

export const getProvider = () => {
  if (typeof window === "undefined") {
    let provider = new ethers.providers.JsonRpcProvider("http://127.0.0.1:8545/");
    return provider;
  } else {
    const { ethereum } = window;
    const provider = new ethers.providers.Web3Provider(ethereum);
    return provider;
  }
};

export const getSigner = () => {
  if (typeof window === "undefined") {
    let privateKey = "0x" + fs.readFileSync("/Users/zhaojunzhang/.metamask_private_key");
    let provider = new ethers.providers.JsonRpcProvider("http://127.0.0.1:8545/");
    // Load the wallet to deploy the contract with
    let wallet = new ethers.Wallet(privateKey, provider);
    return wallet;
  } else {
    const { ethereum } = window;
    const provider = new ethers.providers.Web3Provider(ethereum);
    return provider.getSigner();
  }
};

const createERC20Contract = (address) => {
  const signer = getSigner();
  const transactionsContract = new ethers.Contract(address, ERC20ABI, signer);
  return transactionsContract;
};

export const createEthereumContract = (contractAddress, contractABI, signer) => {
  const transactionsContract = new ethers.Contract(contractAddress, contractABI, signer);

  return transactionsContract;
};

export const createUniswapV3FactoryContract = function () {
  return createEthereumContract(UniswapV3FactoryContractAddress, UNISWAPV3Factory_ABI, getSigner());
};

async function getPoolImmutables(poolContract) {
  const [factory, token0, token1, fee, tickSpacing, maxLiquidityPerTick] = await Promise.all([
    poolContract.factory(),
    poolContract.token0(),
    poolContract.token1(),
    poolContract.fee(),
    poolContract.tickSpacing(),
    poolContract.maxLiquidityPerTick(),
  ]);

  const immutables = {
    factory,
    token0,
    token1,
    fee,
    tickSpacing,
    maxLiquidityPerTick,
  };
  return immutables;
}

async function getPoolState(poolContract) {
  const [liquidity, slot] = await Promise.all([poolContract.liquidity(), poolContract.slot0()]);

  const PoolState = {
    liquidity,
    sqrtPriceX96: slot[0],
    tick: slot[1],
    observationIndex: slot[2],
    observationCardinality: slot[3],
    observationCardinalityNext: slot[4],
    feeProtocol: slot[5],
    unlocked: slot[6],
  };

  return PoolState;
}

export async function getUniswapPool(poolAddress) {
  const provider = getProvider();
  const poolContract = new ethers.Contract(poolAddress, IUniswapV3Pool_json.abi, provider);

  const [immutables, state] = await Promise.all([getPoolImmutables(poolContract), getPoolState(poolContract)]);
  const token0 = createERC20Contract(immutables.token0);
  const token1 = createERC20Contract(immutables.token1);
  const dec = await token0.decimals();
  console.log(dec);
  const TokenA = new Token(1, immutables.token0, await token0.decimals(), await token0.symbol(), await token0.name());
  const TokenB = new Token(1, immutables.token1, await token1.decimals(), await token1.symbol(), await token1.name());

  const poolExample = new Pool(
    TokenA,
    TokenB,
    immutables.fee,
    state.sqrtPriceX96.toString(),
    state.liquidity.toString(),
    state.tick
  );
  return poolExample;
}
